import React from "react";
import { observer } from "mobx-react";
import './styles/index.css'
import {Link} from "react-router-dom";
const StartPage = () => {
    return (
        <div className={`container`}>
            <div className={`main-start-page`}>
                <p className={`title`}>
                    QR Order - Giải pháp giúp khách hàng tự gọi món tại bàn
                </p>
                <p className={`summary`}>
                    Giải pháp giúp nhà hàng tiết kiệm chi phí nhân sự, giảm thiểu thời gian phục vụ và tăng trải nghiệm khách hàng
                </p>
                <div className={`content-main-start-page`}>
                    <div className={`left-content-main-start-page`}>
                        <img height={`200px`} src="/assets/images/food.svg" alt="table-ordering"/>
                    </div>
                    <div className={`right-content-main-start-page`}>
                        <div>
                            <div className={`item-right`}>
                                <p className={`title-item-right`}>
                                    Khởi tạo trang bán hàng
                                </p>
                                <ul>
                                    <li>Thiết lập cấu hình trang bán hàng</li>
                                    <li>Tạo mã QR theo bàn, chỉnh sửa mẫu in mã QR</li>
                                </ul>
                            </div>
                            <div className={`item-right`}>
                                <p className={`title-item-right`}>
                                    Hướng dẫn sử dụng
                                </p>
                                <ul>
                                    <li>In mã QR và dán ở nơi khách hàng dễ dàng nhìn thấy</li>
                                    <li>Khách hàng scan mã QR và gọi món, gọi nhân viên, yêu cầu thanh toán trên trang
                                        bán hàng
                                    </li>
                                    <li>Đơn hàng sẽ được gửi về hệ thống tiếp nhận xử ký</li>
                                </ul>
                            </div>
                            <div className={`btn-start`}>
                                <Link to="/qrcode/cau-hinh" title="Tạo trang">
                                    <span>Tạo trang</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default observer(StartPage)