import React, {useEffect} from "react";
import { observer } from "mobx-react";
import {processingRequestsStore} from "./ProcessingRequestsStore";
import './styles/index.css'
import {settingQrcodeStore} from "../settingQrcode/SettingQrcodeStore";
import SelectAnt from "../../../shared/components/form/SelectAnt";
import {permissionsStore} from "../../settings/permissions/PermissionsStore";
import ReactPaginate from "react-paginate";
import {createSearchParams, useNavigate} from "react-router-dom";
import NoContent from "../../../shared/components/NoContent";
import Loading from "../../../shared/components/loading/Loading";
import ModalCancel from "./components/ModalCancel";
import ModalSelectMachinePos from "./components/ModalSelectMachinePos";
import ModalOrder from "./components/ModalOrder";
import StorageService from "../../../common/service/StorageService";
const ProcessingRequestsPage = () => {
    const navigate = useNavigate();

    const statusRequest = [
        { key: 'PENDING', name: "Đang chờ xử lý" },
        { key: 'PROCESSING', name: "Đang xử lý" },
        { key: 'PREPARING', name: "Chuẩn bị" },
        { key: 'READY', name: "Sẵn sàng" },
        { key: 'COMPLETED', name: "Hoàn thành" },
        { key: 'CANCELED', name: "Đã Hủy" },
    ]

    useEffect(() => {
        processingRequestsStore.isLoading = true
        processingRequestsStore.fetchDataStore().then();
    }, []);
    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        processingRequestsStore.staticParam.page = selectedPage;
        processingRequestsStore.fetchGetOrderRequest();
    };
    const handleTypeRequestClick = (isRequest: string) => {
        const params: any = {};
        params.page = 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        processingRequestsStore.staticParam.page = 0;
        processingRequestsStore.isRequest = isRequest
        processingRequestsStore.fetchGetOrderRequest();
    };
    const handleTypeRequestClick2 = () => {
        const params: any = {};
        params.page = 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        processingRequestsStore.staticParam.page = 0;
        processingRequestsStore.isRequest = 'ALL'
        processingRequestsStore.fetchGetOrderRequest2();
    };
    useEffect(() => {
        if(processingRequestsStore.locationIdActive){
            processingRequestsStore.isLoading = true
            processingRequestsStore.fetchListFloorRoom().then();
            processingRequestsStore.fetchListPos().then();
            // if(processingRequestsStore.listFloorRoom.length > 0){
            //     const dataListFloorRoom =
            //         processingRequestsStore.listFloorRoom?.length > 0 ?
            //             processingRequestsStore.listFloorRoom?.filter((itemFilter:any) => itemFilter.location_id === processingRequestsStore.locationIdActive).map((item: any) => ({
            //                 id: item.id,
            //                 name: `${item.parent_id > 0 ? ``: ""} ${item?.name}`,
            //                 parent_id: item.parent_id,
            //                 total_request: item.total_request,
            //             })) : [];
            //     processingRequestsStore.filterFloorRooms = dataListFloorRoom
            //     if(processingRequestsStore.filterFloorRooms[0]){
            //         processingRequestsStore.floorRoomIdActive = processingRequestsStore.filterFloorRooms[0].id
            //         processingRequestsStore.fetchGetOrderRequest().then();
            //     }
            // }
        }
    }, [processingRequestsStore.locationIdActive]);

    return (
        <div className={`container container-requests`}>
            <div className={`main-requests-page`}>
                <div className={`content-main-requests-page`}>
                    <div className={'left-content-main-requests-page'}>
                        <div className={`filter-requests`}>
                            <SelectAnt
                                value={processingRequestsStore.locationIdActive}
                                onChange={(e: any) => (processingRequestsStore.locationIdActive = e)}
                                options={processingRequestsStore.listStore ? processingRequestsStore.listStore : []}
                            />
                        </div>
                        <div className={`content-flooroom`}>
                            {processingRequestsStore.filterFloorRooms.map((item: any, index: number) => (
                                <div
                                    onClick={() => {
                                        if (processingRequestsStore.floorRoomIdActive !== item.id) {
                                            processingRequestsStore.floorRoomIdActive = item.id
                                            processingRequestsStore.isRequest = 'ALL'
                                            processingRequestsStore.staticParam.page = 0;
                                            const params: any = {};
                                            params.page = 1;
                                            navigate({
                                                search: createSearchParams(params).toString(),
                                            });
                                            processingRequestsStore.fetchListFloorRoomLocation().then();
                                            processingRequestsStore.fetchGetOrderRequest().then();
                                        }
                                    }}
                                    className={`item-floor-room ${processingRequestsStore.floorRoomIdActive === item.id ? `active` : ''} ${item.parent_id > 0 ? `item-floor-room-child` : ''}`}
                                    key={`item-floor-room${index}`}>
                                    <p className={'title'}>{item.name}</p>
                                    <p className={`count`}>{item.total_request} yêu cầu</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={`right-content-main-requests-page`}>
                        <div className={`content-requests`}>
                            <div className={`header-content-requests`}>
                                <div
                                    className={`item-header ${(processingRequestsStore.isRequest === 'ALL' && processingRequestsStore.statusRequest === 'PENDING,READY,PROCESSING,PREPARING') ? 'active' : ''}`}
                                    onClick={() => {
                                        processingRequestsStore.statusRequest = 'PENDING,READY,PROCESSING,PREPARING'
                                        handleTypeRequestClick('ALL')
                                    }}>
                                    <p>Tất cả</p>
                                </div>
                                <div
                                    className={`item-header ${processingRequestsStore.isRequest === 'ORDER' ? 'active' : ''}`}
                                    onClick={() => {
                                        processingRequestsStore.statusRequest = 'PENDING,READY,PROCESSING,PREPARING'
                                        handleTypeRequestClick('ORDER')
                                    }}>
                                    <p>Gọi món
                                        ({processingRequestsStore.metadata.count_request ? processingRequestsStore.metadata.count_request.order_request : 0})</p>
                                </div>
                                <div
                                    className={`item-header ${processingRequestsStore.isRequest === 'CALL_STAFF' ? 'active' : ''}`}
                                    onClick={() => {
                                        processingRequestsStore.statusRequest = 'PENDING,READY,PROCESSING,PREPARING'
                                        handleTypeRequestClick('CALL_STAFF')
                                    }}>
                                    <p>Nhân viên
                                        ({processingRequestsStore.metadata.count_request ? processingRequestsStore.metadata.count_request.call_staff_request : 0})</p>
                                </div>
                                <div
                                    className={`item-header ${processingRequestsStore.isRequest === 'PAY' ? 'active' : ''}`}
                                    onClick={() => {
                                        processingRequestsStore.statusRequest = 'PENDING,READY,PROCESSING,PREPARING'
                                        handleTypeRequestClick('PAY')
                                    }}>
                                    <p>Thanh toán
                                        ({processingRequestsStore.metadata.count_request ? processingRequestsStore.metadata.count_request.pay_request : 0})</p>
                                </div>

                                <div
                                    className={`item-header ${(processingRequestsStore.isRequest === 'ALL' && processingRequestsStore.statusRequest === 'COMPLETED') ? 'active' : ''}`}
                                    onClick={() => {
                                        processingRequestsStore.statusRequest = 'COMPLETED'
                                        handleTypeRequestClick2()
                                    }}>
                                    <p>Đã xử lý</p>
                                </div>
                                <div
                                    className={`item-header ${(processingRequestsStore.isRequest === 'ALL' && processingRequestsStore.statusRequest === 'CANCELED') ? 'active' : ''}`}
                                    onClick={() => {
                                        processingRequestsStore.statusRequest = 'CANCELED'
                                        handleTypeRequestClick2()
                                    }}>
                                    <p>Hủy</p>
                                </div>
                            </div>
                            {/*<Loading/>*/}
                            {processingRequestsStore.isLoading ? (
                                <>
                                    <Loading/>
                                </>
                            ) : processingRequestsStore.listRequest.length <= 0 ? (
                                <>
                                    <NoContent/>
                                </>
                            ) : (
                                <>
                                    <div className={`table-content-requests`}>
                                        {processingRequestsStore.listRequest.map((item: any, index: number) => (
                                            <div className={`item-requests`} key={`item-requests${index}`}>
                                                <div className={`name-table`}>
                                                    <p>{item.table_name}</p>
                                                </div>
                                                <div className={`content-item ${processingRequestsStore.statusRequest === 'COMPLETED' ? 'content-item-completed' : ''} ${processingRequestsStore.statusRequest === 'CANCELED' ? 'content-item-canceled' : ''}`}>
                                                    <div className={`date`}>
                                                        <div>
                                                            {(processingRequestsStore.statusRequest === 'CANCELED' || processingRequestsStore.statusRequest === 'COMPLETED') ? (
                                                                <>
                                                                    <p>Tạo: {new Date(item.created_at).toLocaleTimeString("en-GB")} {new Date(item.created_at).toLocaleDateString("en-GB")}{" "}</p>
                                                                    <p>Xử lý: {new Date(item.created_at).toLocaleTimeString("en-GB")} {new Date(item.end_time).toLocaleDateString("en-GB")}{" "}</p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p>{new Date(item.created_at).toLocaleTimeString("en-GB")} {new Date(item.created_at).toLocaleDateString("en-GB")}{" "}</p>
                                                                </>
                                                            )}

                                                            {item.type_request === 'CALL_STAFF' ? ( // Gọi nhân viên
                                                                <>
                                                                    <span className={`staff`}>Yêu cầu nhân viên</span>
                                                                </>
                                                            ) : item.type_request === 'PAY' ? ( // Gọi thanh toán
                                                                <>
                                                                    <span className={`pay`}>Yêu cầu thanh toán</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span>Yêu cầu gọi món</span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className={`name-customer`}>
                                                        <p>{item?.order_session?.customer_session_name}</p>
                                                    </div>
                                                    {/*--Những yêu cầu chưa được xử lý--*/}
                                                    {(processingRequestsStore.statusRequest === 'PENDING,READY,PROCESSING,PREPARING') && (
                                                        <>
                                                            {item.type_request === 'CALL_STAFF' ? ( // Gọi nhân viên
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.note}</p>
                                                                    </div>
                                                                    <div className={`btn-item`}>
                                                                        <div className={`btn-cancel`} onClick={() => {
                                                                            processingRequestsStore.showModal()
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'CALL_STAFF'
                                                                        }}>
                                                                            <p>Hủy</p>
                                                                        </div>
                                                                        <div className={`btn-completed`} onClick={() => {
                                                                            processingRequestsStore.fetchApproveRequest(item.id, 'CALL_STAFF', 'COMPLETED')
                                                                        }}>
                                                                            <p className={`staff`}>Phục vụ</p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : item.type_request === 'PAY' ? ( // Gọi thanh toán
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.description}</p>
                                                                    </div>
                                                                    <div className={`btn-item`}>
                                                                        <div className={`btn-cancel`} onClick={() => {
                                                                            processingRequestsStore.showModal()
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'PAY'
                                                                        }}>
                                                                            <p>Hủy</p>
                                                                        </div>
                                                                        <div className={`btn-completed`} onClick={() => {
                                                                            processingRequestsStore.showModalSelectMachinePos()
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'PAY'
                                                                            if (item.order_session.merge_code) {
                                                                                processingRequestsStore.pos_id = item?.order_session?.merge_code ? parseInt(item?.order_session?.merge_code) : undefined
                                                                            }
                                                                        }}>
                                                                            <p className={`pay`}>
                                                                                {item.status === 'PENDING' ? (
                                                                                    'Thanh toán'
                                                                                ) : (
                                                                                    statusRequest.find((itemStatus: any) => itemStatus.key === item.status)?.name
                                                                                )}

                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={`nd`}>
                                                                        {item?.request_content_client?.cart_items?.length > 0 && (
                                                                            <ul>
                                                                                {item.request_content_client?.cart_items.map((item: any, index: number) => (
                                                                                    <li>
                                                                            <span key={`item-order${index}`}>
                                                                                {item.title} {item.attribute_compare ? `(${item.attribute_compare})` : ''} (x{item.quantity})
                                                                            </span>
                                                                                        {item.note && (
                                                                                            <span className={`note-item-order`}>
                                                                                    Ghi chú: {item.note}
                                                                                </span>
                                                                                        )}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                        {item.request_content_client?.note_order && (
                                                                            <p className={`note-order-all`}>
                                                                                Ghi chú đơn
                                                                                hàng: {item.request_content_client.note_order}
                                                                            </p>
                                                                        )}

                                                                    </div>
                                                                    <div className={`btn-item`}>
                                                                        <div className={`btn-cancel`} onClick={() => {
                                                                            processingRequestsStore.showModal()
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'ORDER'
                                                                        }}>
                                                                            <p>Hủy</p>
                                                                        </div>
                                                                        <div className={`btn-completed`} onClick={() => {
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'ORDER'
                                                                            processingRequestsStore.showModalOrder()
                                                                        }}>
                                                                            <p>Xử lý</p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    {/*--End--*/}

                                                    {/*--Những yêu cầu đã được xử lý--*/}
                                                    {processingRequestsStore.statusRequest === 'COMPLETED' && (
                                                        <>
                                                            {item.type_request === 'CALL_STAFF' ? ( // Gọi nhân viên
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.note}</p>
                                                                    </div>
                                                                </>
                                                            ) : item.type_request === 'PAY' ? ( // Gọi thanh toán
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.description}</p>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={`nd`}>
                                                                        {item?.request_content_client?.cart_items?.length > 0 && (
                                                                            <ul>
                                                                                {item.request_content_client?.cart_items.map((item: any, index: number) => (
                                                                                    <li>
                                                                            <span key={`item-order${index}`}>
                                                                                {item.title} {item.attribute_compare ? `(${item.attribute_compare})` : ''} (x{item.quantity})
                                                                            </span>
                                                                                        {item.note && (
                                                                                            <span className={`note-item-order`}>
                                                                                    Ghi chú: {item.note}
                                                                                </span>
                                                                                        )}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                        {item.request_content_client?.note_order && (
                                                                            <p className={`note-order-all`}>
                                                                                Ghi chú đơn
                                                                                hàng: {item.request_content_client.note_order}
                                                                            </p>
                                                                        )}

                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    {/*--End--*/}

                                                    {/*--Những yêu cầu đã hủy--*/}
                                                    {processingRequestsStore.statusRequest === 'CANCELED' && (
                                                        <>
                                                            {item.type_request === 'CALL_STAFF' ? ( // Gọi nhân viên
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.note}</p>
                                                                    </div>
                                                                </>
                                                            ) : item.type_request === 'PAY' ? ( // Gọi thanh toán
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.description}</p>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={`nd`}>
                                                                        {item?.request_content_client?.cart_items?.length > 0 && (
                                                                            <ul>
                                                                                {item.request_content_client?.cart_items.map((item: any, index: number) => (
                                                                                    <li>
                                                                            <span key={`item-order${index}`}>
                                                                                {item.title} {item.attribute_compare ? `(${item.attribute_compare})` : ''} (x{item.quantity})
                                                                            </span>
                                                                                        {item.note && (
                                                                                            <span className={`note-item-order`}>
                                                                                    Ghi chú: {item.note}
                                                                                </span>
                                                                                        )}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                        {item.request_content_client?.note_order && (
                                                                            <p className={`note-order-all`}>
                                                                                Ghi chú đơn
                                                                                hàng: {item.request_content_client.note_order}
                                                                            </p>
                                                                        )}

                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className={'reason'}>
                                                                {JSON.parse(item.response_own_pos)?.reason_refusal === 'REASON_OTHER' ? (
                                                                    <>
                                                                        <p>Lý do: {JSON.parse(item.response_own_pos)?.reason_refusal_other_text}</p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>Lý do: khách không ở quán</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                    {/*--End--*/}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {processingRequestsStore.isLoading ? "" : Math.ceil(processingRequestsStore.metadata.total_page) > 1 && (
                                        <ReactPaginate
                                            previousLabel={
                                                <img
                                                    src="/assets/icon/fi-rr-angle-small-left.svg"
                                                    alt="right"
                                                />
                                            }
                                            nextLabel={
                                                <img
                                                    src="/assets/icon/fi-rr-angle-small-right.svg"
                                                    alt="right"
                                                />
                                            }
                                            breakLabel={"..."}
                                            pageCount={Math.ceil(
                                                processingRequestsStore.metadata.total_page
                                            )}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            activeClassName={"active"}
                                            forcePage={processingRequestsStore.staticParam.page}
                                        />
                                    )}
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <div className={`main-requests-page-mobile`}> {/*Nội dung mobile*/}
                <div className={`content-main-requests-page`}>
                    <div className={`filter-requests`}>
                        <SelectAnt
                            value={processingRequestsStore.locationIdActive}
                            onChange={(e: any) => (processingRequestsStore.locationIdActive = e)}
                            options={processingRequestsStore.listStore ? processingRequestsStore.listStore : []}
                        />

                        <SelectAnt
                            value={processingRequestsStore.floorRoomIdActive}
                            onChange={(e: any) => {
                                if (processingRequestsStore.floorRoomIdActive !== e) {
                                    processingRequestsStore.floorRoomIdActive = e
                                    processingRequestsStore.isRequest = 'ALL'
                                    processingRequestsStore.staticParam.page = 0;
                                    const params: any = {};
                                    params.page = 1;
                                    navigate({
                                        search: createSearchParams(params).toString(),
                                    });
                                    processingRequestsStore.fetchListFloorRoomLocation().then();
                                    processingRequestsStore.fetchGetOrderRequest().then();
                                }
                            }}
                            options={processingRequestsStore.filterFloorRooms ? processingRequestsStore.filterFloorRooms : []}
                        />
                    </div>
                    <div className={`content-requests`}>
                        <div className={`header-content-requests`}>
                            <div
                                className={`item-header ${(processingRequestsStore.isRequest === 'ALL' && processingRequestsStore.statusRequest === 'PENDING,READY,PROCESSING,PREPARING') ? 'active' : ''}`}
                                onClick={() => {
                                    processingRequestsStore.statusRequest = 'PENDING,READY,PROCESSING,PREPARING'
                                    handleTypeRequestClick('ALL')
                                }}>
                                <p>Tất cả</p>
                            </div>
                            <div
                                className={`item-header ${processingRequestsStore.isRequest === 'ORDER' ? 'active' : ''}`}
                                onClick={() => {
                                    processingRequestsStore.statusRequest = 'PENDING,READY,PROCESSING,PREPARING'
                                    handleTypeRequestClick('ORDER')
                                }}>
                                <p>Gọi món
                                    ({processingRequestsStore.metadata.count_request ? processingRequestsStore.metadata.count_request.order_request : 0})</p>
                            </div>
                            <div
                                className={`item-header ${processingRequestsStore.isRequest === 'CALL_STAFF' ? 'active' : ''}`}
                                onClick={() => {
                                    processingRequestsStore.statusRequest = 'PENDING,READY,PROCESSING,PREPARING'
                                    handleTypeRequestClick('CALL_STAFF')
                                }}>
                                <p>Nhân viên
                                    ({processingRequestsStore.metadata.count_request ? processingRequestsStore.metadata.count_request.call_staff_request : 0})</p>
                            </div>
                            <div
                                className={`item-header ${processingRequestsStore.isRequest === 'PAY' ? 'active' : ''}`}
                                onClick={() => {
                                    processingRequestsStore.statusRequest = 'PENDING,READY,PROCESSING,PREPARING'
                                    handleTypeRequestClick('PAY')
                                }}>
                                <p>Thanh toán
                                    ({processingRequestsStore.metadata.count_request ? processingRequestsStore.metadata.count_request.pay_request : 0})</p>
                            </div>
                        </div>
                        <div className={`header-content-requests header-content-requests-2`}>
                            <div className={`item-header ${(processingRequestsStore.isRequest === 'ALL' && processingRequestsStore.statusRequest === 'COMPLETED') ? 'active' : ''}`} onClick={() => {
                                processingRequestsStore.statusRequest = 'COMPLETED'
                                handleTypeRequestClick2()
                            }}>
                                <p>Đã xử lý</p>
                            </div>
                            <div className={`item-header ${(processingRequestsStore.isRequest === 'ALL' && processingRequestsStore.statusRequest === 'CANCELED') ? 'active' : ''}`} onClick={() => {
                                processingRequestsStore.statusRequest = 'CANCELED'
                                handleTypeRequestClick2()
                            }}>
                                <p>Hủy</p>
                            </div>
                        </div>
                        {processingRequestsStore.isLoading ? (
                            <>
                                <Loading/>
                            </>
                        ) : processingRequestsStore.listRequest.length <= 0 ? (
                            <>
                                <NoContent/>
                            </>
                        ) : (
                            <>
                                <div className={`table-content-requests`}>
                                    {processingRequestsStore.listRequest.map((item: any, index: number) => (
                                        <div className={`item-requests`} key={`item-requests${index}`}>
                                            <div className={`name-table`}>
                                                <p>{item.table_name}</p>
                                            </div>
                                            <div className={`content-item`}>
                                                <div className={`name-customer-date`}>
                                                    <div className={`date`}>
                                                        {(processingRequestsStore.statusRequest === 'CANCELED' || processingRequestsStore.statusRequest === 'COMPLETED') ? (
                                                            <>
                                                                <p>
                                                                    <img src="/assets/icon/calendar.svg"
                                                                         alt="calendar"/>Tạo: {new Date(item.created_at).toLocaleTimeString("en-GB")}  {new Date(item.created_at).toLocaleDateString("en-GB")}{" "}
                                                                </p>
                                                                <p>
                                                                    <img src="/assets/icon/calendar.svg"
                                                                         alt="calendar"/>Xử lý: {new Date(item.created_at).toLocaleTimeString("en-GB")} {new Date(item.end_time).toLocaleDateString("en-GB")}{" "}
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p>
                                                                    <img src="/assets/icon/calendar.svg" alt="calendar"/>{new Date(item.created_at).toLocaleTimeString("en-GB")} {new Date(item.created_at).toLocaleDateString("en-GB")}{" "}
                                                                </p>
                                                            </>
                                                        )}

                                                    </div>
                                                    <div className={`name-customer`}>
                                                        <p>
                                                        <img src="/assets/icon/iconuser.svg" alt="user"/>
                                                        {item?.order_session?.customer_session_name}
                                                    </p>
                                                    </div>
                                                </div>
                                                {/*--Những yêu cầu chưa được xử lý--*/}
                                                {(processingRequestsStore.statusRequest === 'PENDING,READY,PROCESSING,PREPARING') && (
                                                    <>
                                                        {item.type_request === 'CALL_STAFF' ? ( // Gọi nhân viên
                                                            <>
                                                                <div className={`nd`}>
                                                                    <p>{item.request_content_client.note}</p>
                                                                </div>
                                                                <div className={`bottom-requests`}>
                                                                    <div className={`type-item-requests`}>
                                                                        <span className={`staff`}>Nhân viên</span>
                                                                    </div>
                                                                    <div className={`btn-item`}>
                                                                        <div className={`btn-cancel`} onClick={() => {
                                                                            processingRequestsStore.showModal()
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'CALL_STAFF'
                                                                        }}>
                                                                            <img src="/assets/icon/cross.svg" alt="user"/>
                                                                        </div>
                                                                        <div className={`btn-completed`} onClick={() => {
                                                                            processingRequestsStore.fetchApproveRequest(item.id, 'CALL_STAFF', 'COMPLETED')
                                                                        }}>
                                                                            <img src="/assets/icon/action.svg" alt="user"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : item.type_request === 'PAY' ? ( // Gọi thanh toán
                                                            <>
                                                                <div className={`nd`}>
                                                                    <p>{item.request_content_client.description}</p>
                                                                </div>
                                                                <div className={`bottom-requests`}>
                                                                    <div className={`type-item-requests`}>
                                                                        <span className={`pay`}>Thanh toán</span>
                                                                    </div>
                                                                    <div className={`btn-item`}>
                                                                        <div className={`btn-cancel`} onClick={() => {
                                                                            processingRequestsStore.showModal()
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'PAY'
                                                                        }}>
                                                                            <img src="/assets/icon/cross.svg" alt="user"/>
                                                                        </div>
                                                                        <div className={`btn-completed`} onClick={() => {
                                                                            processingRequestsStore.showModalSelectMachinePos()
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'PAY'
                                                                            if (item.order_session.merge_code) {
                                                                                processingRequestsStore.pos_id = item?.order_session?.merge_code ? parseInt(item?.order_session?.merge_code) : undefined
                                                                            }
                                                                        }}>
                                                                            <img src="/assets/icon/action.svg" alt="user"/>
                                                                            {/*<p className={`pay`}>*/}
                                                                            {/*    {item.status === 'PENDING' ? (*/}
                                                                            {/*        'Thanh toán'*/}
                                                                            {/*    ) : (*/}
                                                                            {/*        statusRequest.find((itemStatus: any) => itemStatus.key === item.status)?.name*/}
                                                                            {/*    )}*/}

                                                                            {/*</p>*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className={`nd`}>
                                                                    {item?.request_content_client?.cart_items?.length > 0 && (
                                                                        <ul>
                                                                            {item.request_content_client?.cart_items.map((item: any, index: number) => (
                                                                                <li>
                                                                            <span key={`item-order${index}`}>
                                                                                - {item.title} {item.attribute_compare ? `(${item.attribute_compare})` : ''} (x{item.quantity})
                                                                            </span>
                                                                                    {item.note && (
                                                                                        <span className={`note-item-order`}>
                                                                                    Ghi chú: {item.note}
                                                                                </span>
                                                                                    )}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                    {item.request_content_client?.note_order && (
                                                                        <p className={`note-order-all`}>
                                                                            Ghi chú đơn
                                                                            hàng: {item.request_content_client.note_order}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                <div className={`bottom-requests`}>
                                                                    <div className={`type-item-requests`}>
                                                                        <span>Gọi món</span>
                                                                    </div>
                                                                    <div className={`btn-item`}>
                                                                        <div className={`btn-cancel`} onClick={() => {
                                                                            processingRequestsStore.showModal()
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'ORDER'
                                                                        }}>
                                                                            <img src="/assets/icon/cross.svg" alt="user"/>
                                                                        </div>
                                                                        <div className={`btn-completed`} onClick={() => {
                                                                            processingRequestsStore.paramsModal.id = item.id
                                                                            processingRequestsStore.paramsModal.type = 'ORDER'
                                                                            processingRequestsStore.showModalOrder()
                                                                        }}>
                                                                            <img src="/assets/icon/action.svg" alt="user"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {/*--End--*/}

                                                {/*--Những yêu cầu được xử lý--*/}
                                                    {(processingRequestsStore.statusRequest === 'COMPLETED') && (
                                                        <>
                                                            {item.type_request === 'CALL_STAFF' ? ( // Gọi nhân viên
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.note}</p>
                                                                    </div>
                                                                    <div className={`bottom-requests`}>
                                                                        <div className={`type-item-requests`}>
                                                                            <span className={`staff`}>Nhân viên</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : item.type_request === 'PAY' ? ( // Gọi thanh toán
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.description}</p>
                                                                    </div>
                                                                    <div className={`bottom-requests`}>
                                                                        <div className={`type-item-requests`}>
                                                                            <span className={`pay`}>Thanh toán</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={`nd`}>
                                                                        {item?.request_content_client?.cart_items?.length > 0 && (
                                                                            <ul>
                                                                                {item.request_content_client?.cart_items.map((item: any, index: number) => (
                                                                                    <li>
                                                                                <span key={`item-order${index}`}>
                                                                                    - {item.title} {item.attribute_compare ? `(${item.attribute_compare})` : ''} (x{item.quantity})
                                                                                </span>
                                                                                        {item.note && (
                                                                                            <span className={`note-item-order`}>
                                                                                        Ghi chú: {item.note}
                                                                                    </span>
                                                                                        )}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                        {item.request_content_client?.note_order && (
                                                                            <p className={`note-order-all`}>
                                                                                Ghi chú đơn
                                                                                hàng: {item.request_content_client.note_order}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                    <div className={`bottom-requests`}>
                                                                        <div className={`type-item-requests`}>
                                                                            <span>Gọi món</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                {/*--End--*/}

                                                {/*--Những yêu cầu hủy--*/}
                                                    {(processingRequestsStore.statusRequest === 'CANCELED') && (
                                                        <>
                                                            <div className={'reason'}>
                                                                {JSON.parse(item.response_own_pos)?.reason_refusal === 'REASON_OTHER' ? (
                                                                    <>
                                                                        <p>Lý do: {JSON.parse(item.response_own_pos)?.reason_refusal_other_text}</p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>Lý do: khách không ở quán</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                            {item.type_request === 'CALL_STAFF' ? ( // Gọi nhân viên
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.note}</p>
                                                                    </div>
                                                                    <div className={`bottom-requests`}>
                                                                        <div className={`type-item-requests`}>
                                                                            <span className={`staff`}>Nhân viên</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : item.type_request === 'PAY' ? ( // Gọi thanh toán
                                                                <>
                                                                    <div className={`nd`}>
                                                                        <p>{item.request_content_client.description}</p>
                                                                    </div>
                                                                    <div className={`bottom-requests`}>
                                                                        <div className={`type-item-requests`}>
                                                                            <span className={`pay`}>Thanh toán</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={`nd`}>
                                                                        {item?.request_content_client?.cart_items?.length > 0 && (
                                                                            <ul>
                                                                                {item.request_content_client?.cart_items.map((item: any, index: number) => (
                                                                                    <li>
                                                                                    <span key={`item-order${index}`}>
                                                                                        - {item.title} {item.attribute_compare ? `(${item.attribute_compare})` : ''} (x{item.quantity})
                                                                                    </span>
                                                                                        {item.note && (
                                                                                            <span
                                                                                                className={`note-item-order`}>
                                                                                            Ghi chú: {item.note}
                                                                                        </span>
                                                                                        )}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                        {item.request_content_client?.note_order && (
                                                                            <p className={`note-order-all`}>
                                                                                Ghi chú đơn
                                                                                hàng: {item.request_content_client.note_order}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                    <div className={`bottom-requests`}>
                                                                        <div className={`type-item-requests`}>
                                                                            <span>Gọi món</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                {/*--End--*/}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {processingRequestsStore.isLoading ? "" : Math.ceil(processingRequestsStore.metadata.total_page) > 1 && (
                                    <ReactPaginate
                                        previousLabel={
                                            <img
                                                src="/assets/icon/fi-rr-angle-small-left.svg"
                                                alt="right"
                                            />
                                        }
                                        nextLabel={
                                            <img
                                                src="/assets/icon/fi-rr-angle-small-right.svg"
                                                alt="right"
                                            />
                                        }
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(
                                            processingRequestsStore.metadata.total_page
                                        )}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                        forcePage={processingRequestsStore.staticParam.page}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ModalCancel/>
            <ModalSelectMachinePos/>
            <ModalOrder/>
        </div>
    )
}
export default observer(ProcessingRequestsPage)