import { makeAutoObservable, toJS } from "mobx";
import { productInPosService } from "./ProductInPosService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import { productService } from "../../products/product/ProductService";
import Constants from "../../../common/constants/Constants";
import {posService} from "../../settings/pos/PosService";
import StorageService from "../../../common/service/StorageService";
import { productInPosCartStore } from "./ProductInPosCartStore";

export type TFormCustomer = {
  id?: string;
  name: string;
  phone: string;
  email?: string;
  province_id?: string | null;
  district_id?: string | null;
  ward_id?: string | null;
  address?: string;
  location_id: string;
};
interface StaticParam {
  page: number;
  per_page: number;
}

class ProductInPostStore {
  idCustomer: string | null = "";
  typeModal: string = "add";
  idCurrentTable: string | undefined = "";

  isBarcode: boolean = false;
  isLoading: boolean = false;
  isLoadingProduct: boolean = false;
  isLoadingDetailPos: boolean = false;
  isHoldModal: boolean = false;
  isOpenModalListCustomer: boolean = false;
  isOpenModalAddEditCustomer: boolean = false;
  isLoadingDetailTable: boolean = false
  isLoadingRefeshData: boolean = false
  dataPosMachine: any = {};

  dataCustomer: any[] = [];
  dataProduct: any[] = [];
  listProvince: any[] = [];
  listDistrict: any[] = [];
  listWard: any[] = [];
  dataInfoTable: any = {};


  isModalVisible: boolean = false;

  dataFormCustomer: TFormCustomer = {
    id: "",
    name: "",
    phone: "",
    email: "",
    province_id: "",
    district_id: "",
    ward_id: "",
    address: "",
    location_id: "",
  };

  staticParam: StaticParam = {
    page: 1,
    per_page: 18,
  };

  
  metadata: any = {
    total: null,
    page: 1,
    total_page: null,
    size: null,
  };

  dataFilterCustomer: any = {
    phone: "",
  };

  dataFilterProduct: any = {
    search: "",
    category_id: ""
  };

  clearFormCustomer() {
    this.dataFormCustomer = {
      name: "",
      phone: "",
      email: "",
      province_id: "",
      district_id: "",
      ward_id: "",
      address: "",
      location_id: "",
    };
  }

  constructor() {
    this.initializeParams();
    makeAutoObservable(this);
  }

  openModal = () => {
    this.isModalVisible = true;
  };

  closeModal = () => {
    this.isModalVisible = false;
  };

  initializeParams() {
    const query = new URLSearchParams(window.location.search);
    this.dataFilterProduct.search = query.get("search")
      ? String(query.get("search"))
      : "";
  }

  async getListCustomer() {
    this.isLoading = true;
    const result = await productInPosService.fetchAllCustomer();
    this.isLoading = false;

    if (result.status === HttpStatusCode.Ok) {
      this.dataCustomer = result.body.data;
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async createCustomer(form: any) {
    const {
      name,
      phone,
      email,
      province_id,
      district_id,
      ward_id,
      address,
      location_id,
    } = this.dataFormCustomer;

    const data = {
      name: name,
      phone: phone,
      email: email,
      province_id: province_id,
      district_id: district_id,
      ward_id: ward_id,
      address: address,
      location_id: location_id,
    };
    this.isLoading = true;
    this.isHoldModal = true;
    const result = await productInPosService.createCustomer(data);
    this.isLoading = false;
    this.isHoldModal = false;
    if (result.status === HttpStatusCode.Ok) {
      toastUtils.success("Thêm mới thành công", "");
      this.isOpenModalAddEditCustomer = false;
      this.isOpenModalListCustomer = true;
      form.resetFields();
      this.clearFormCustomer();
      this.dataCustomer.unshift(result.body);
    } else {
      if (
        result.body?.message[0] === "the_email_must_be_a_valid_email_address"
      ) {
        toastUtils.error("Email không hợp lệ", "2");
        return;
      }
      if (result.body.message?.customer === "customer_already_exist") {
        toastUtils.error("Số điện thoại đã tồn tại", "2");
        return;
      }

      toastUtils.error(result.body.message, "2");
    }
  }

  async updateCustomer() {
    const {
      name,
      phone,
      email,
      province_id,
      district_id,
      ward_id,
      address,
      location_id,
    } = this.dataFormCustomer;

    const data = {
      name: name,
      phone: phone,
      email: email,
      province_id: province_id,
      district_id: district_id,
      ward_id: ward_id,
      address: address,
      location_id: location_id,
    };
    this.isLoading = true;
    this.isHoldModal = true;
    const result = await productInPosService.updateCustomer(
      this.idCustomer as string,
      data
    );
    this.isLoading = false;
    this.isHoldModal = false;
    if (result.status === HttpStatusCode.Ok) {
      toastUtils.success("Cập nhật thành công", "");
      const updatedArrCustomer = this.dataCustomer.map((item) =>
        item.id === this.dataFormCustomer.id
          ? {
              ...item,
              ...this.dataFormCustomer,
              city_id: this.dataFormCustomer.province_id,
            }
          : item
      );

      this.dataCustomer = updatedArrCustomer;
      this.dataFormCustomer = {} as any;
      this.isOpenModalAddEditCustomer = false;
      this.isOpenModalListCustomer = true;
    } else {
      if (
        result.body?.message[0] === "the_email_must_be_a_valid_email_address"
      ) {
        toastUtils.error("Email không hợp lệ", "2");
        return;
      }
      if (result.body?.message?.customer) {
        toastUtils.error("Số điện thoại đã tồn tại", "2");
        return;
      }
      toastUtils.error(result.body.message, "2");
    }
  }

  async getListProduct() {
    const { search,category_id } = this.dataFilterProduct;
    let { page, per_page } = this.staticParam;
    const paramsTagProduct = {
      search: search,
      category_id: category_id,
      page: page + 1,
      per_page: per_page || Constants.maxSize,
    };
    this.isLoadingProduct = true;
    const result = await productService.fetchListProduct(paramsTagProduct);
    this.isLoadingProduct = false;

    if (result.status === HttpStatusCode.Ok) {
      this.dataProduct = result.body.data;
      this.metadata = result.body.metadata
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async getDetailMachinePos(idPos: string) {
    this.isLoadingDetailPos = true;
    const result = await posService.fetchPosGettingSpecific(idPos);
    this.isLoadingDetailPos = false;
    if (result.status === HttpStatusCode.Ok) {
      this.dataPosMachine = result.body;
    } else {
      this.dataPosMachine = {};
      this.isLoadingDetailPos = false;
    }
  }

  async getProvinces() {
    const result = await productInPosService.fetchProvices();
    if (result.status === HttpStatusCode.Ok) {
      this.listProvince = result.body;
    }
  }

  async getDistrict(province_id: string) {
    if (province_id) {
      const result = await productInPosService.fetchDistricts(province_id);
      if (result.status === HttpStatusCode.Ok) {
        this.listDistrict = result.body;
      }
    }
  }

  async getWard(district_id: string) {
    if (district_id) {
      const result = await productInPosService.fetchWards(district_id);
      if (result.status === HttpStatusCode.Ok) {
        this.listWard = result.body;
      }
    }
  }

  async getDataInfoTable(callback?: any, loading?: boolean ): Promise<void> {
    this.isLoadingDetailTable = true
    this.isLoadingRefeshData = loading as boolean
    const result = await productInPosService.getInfoTable(this.idCurrentTable)
    this.isLoadingDetailTable = false
    this.isLoadingRefeshData = false
    if (result.status === HttpStatusCode.Ok) {
      this.dataInfoTable = result.body;
      const posID = productInPosCartStore.postId;
      const localStorageKey = `cartPos${posID}-${this.idCurrentTable}`;
      // Lấy giỏ hàng hiện tại
      let currentCart = StorageService.getObjectStore(localStorageKey);
      if (currentCart) {
        currentCart.merge_dining_tables_id = result.body?.dining_table_session?.merge_dining_tables_name || "";
      }
      // Cập nhật giỏ hàng trong localStorage
      StorageService.setObjectStore(localStorageKey, currentCart);
      callback && callback?.()
    }
  }
}

export const productInPostStore = new ProductInPostStore();
