import React from "react";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {permissionSiteStore} from "../../../../pages/permissionSite/PermissionSiteStore";
import ModalPermission from "../../../../pages/permissionSite/modalPermission/ModalPermission";
import {CollapseProps} from "antd";
import {headerStore} from "../HeaderStore";

const NavbarMobile = () => {
    const menuProduct = [ // Menu hàng hóa
        {title: 'Sản phẩm', link: '/hang-hoa/san-pham'},
        {title: 'Tồn kho', link: '/hang-hoa/ton-kho'},
        {title: 'Nhóm sản phẩm', link: '/hang-hoa/nhom-san-pham'},
        {title: 'Danh mục', link: '/hang-hoa/danh-muc'},
        {title: 'Thương hiệu', link: '/hang-hoa/thuong-hieu'},
        {title: 'Thuộc tính', link: '/hang-hoa/thuoc-tinh'},
        {title: 'Mã giảm giá', link: '/hang-hoa/ma-giam-gia'},
        {title: 'Thẻ sản phẩm', link: '/hang-hoa/the-san-pham'},
        {title: 'Nhãn', link: '/hang-hoa/nhan'},
    ]

    return (
        <>
            <ul className="navbar-list">
                <li className=" mobile-hidden">
                    <Link to="/" title="Tổng quan" onClick={() => {
                        headerStore.isMenuMobile = false
                    }}>
                        <span>Tổng quan</span>
                    </Link>
                </li>
                <li className="navbar-list-item mobile-hidden">
                    <div title="Hàng hóa" className={``} onClick={() => {
                        headerStore.isMenuMobileProduct ? headerStore.isMenuMobileProduct = false : headerStore.isMenuMobileProduct = true
                    }}>
                        <span>Hàng hóa</span>
                        {headerStore.isMenuMobileProduct === false && (
                            <>
                                <img style={{width: '20px'}} src="/assets/icon/arrow-down.svg" alt="Tổng quan"/>
                            </>
                        )}
                    </div>
                    <ul className={`dropdown-list ${headerStore.isMenuMobileProduct ? '' : 'hidden'}`}>
                        {menuProduct.map((item:any) => (
                            <>
                                <li className="dropdown-list-item">
                                    <Link to={item.link} title={item.title} onClick={() => {
                                        headerStore.isMenuMobile = false
                                    }}>
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            </>
                        ))}
                    </ul>
                </li>
                <li className="navbar-list-item mobile-hidden">
                    <Link to="/don-hang" title="Đơn hàng" onClick={() => {
                        headerStore.isMenuMobile = false
                    }}>
                        <span>Đơn hàng</span>
                    </Link>
                </li>

                {/*<li*/}
                {/*    className="navbar-list-item"*/}
                {/*    onClick={() => {*/}
                {/*        permissionSiteStore.handlePermissionCheck();*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <a style={{cursor: "pointer"}}>*/}
                {/*        <span>Bán hàng trên website</span>*/}
                {/*    </a>*/}
                {/*</li>*/}
                <li className="navbar-list-item">
                    <div onClick={() => {
                        headerStore.isMenuMobileQrcode ? headerStore.isMenuMobileQrcode = false : headerStore.isMenuMobileQrcode = true
                    }}>
                        <span>QR code</span>
                        {headerStore.isMenuMobileQrcode === false && (
                            <>
                                <img style={{width: '20px'}} src="/assets/icon/arrow-down.svg" alt="Tổng quan"/>
                            </>
                        )}
                    </div>
                    <ul className={`dropdown-list ${headerStore.isMenuMobileQrcode ? '' : 'hidden'}`}>
                        <li className="dropdown-list-item">
                            <Link to="/qrcode/khoi-tao" title="Khởi tạo" onClick={() => {
                                headerStore.isMenuMobile = false
                            }}>
                                <span>Khởi tạo</span>
                            </Link>
                        </li>
                        <li className="dropdown-list-item">
                            <Link to="/qrcode/thiet-lap-ma-qr" title="Thiết lập mã QR" onClick={() => {
                                headerStore.isMenuMobile = false
                            }}>
                                <span>Thiết lập mã QR</span>
                            </Link>
                        </li>
                        <li className="dropdown-list-item">
                            <Link to="/qrcode/xu-ly-yeu-cau" title="Xử lý yêu cầu" onClick={() => {
                                headerStore.isMenuMobile = false
                            }}>
                                <span>Xử lý yêu cầu</span>
                            </Link>
                        </li>
                    </ul>
                </li>
                {permissionSiteStore.isOpenModal && <ModalPermission/>}
            </ul>
        </>
    )
}
export default observer(NavbarMobile);